body, input, textarea, button {
  //font-style: italic;
}

h1,
h2,
h3,
a {
  text-transform: uppercase;
}

.btn {
  text-transform: lowercase;
}

.pre-arrow::before {
  content: '> ';
}

blockquote {
  padding: 1rem 1rem 1rem 2rem;
  position: relative;
  opacity: 0.9;
  font-style: italic;

  p:last-of-type {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    background: #aaa;
    top: 0;
    bottom: 0;
    left: 0;
  }
}