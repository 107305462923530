@import "@/scss/_vue-globals.scss";















































































.navbar {
    flex-wrap: nowrap;

    @include media-breakpoint-down(lg) {
        padding: $navbar-padding-x / 1.5;
    }

    @include media-breakpoint-down(xs) {
        padding: $navbar-padding-x / 2.5;
    }

    img {
        max-height: 2.5rem;
    }

    .brand {
        z-index: 2;

        a {
            font-weight: $font-weight-bold;
            color: $primary;
            transition: $transition-base;
            text-transform: lowercase;
        }
    }

    .helper-text {
        opacity: 0;
        transition: $transition-base;
    }

    .cart {
        color: $primary;
        font-size: 2rem;
        margin-right: 2rem;
        position: relative;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        @include media-breakpoint-down(md) {
            color: $white;
        }

        .cart-items {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-top: 3px;
            font-size: 50%;
        }
    }

    .navbar-toggler-wrapper {
        cursor: pointer;
        z-index: 1;
        outline: none;
        display: flex;

        .helper-text {
            text-transform: lowercase;
            margin-right: $spacer;

            @include media-breakpoint-down(md) {
                color: $white;
            }
        }
    }

    &.collapsed {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .brand a {
            color: $white;
        }

        .helper-text {
            opacity: 1;
        }
    }
}

.menu-overlay {
    display: flex;
    position: fixed;
    background: $white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    color: $primary;
    overscroll-behavior: none;
    touch-action: none; /* prevent scrolling */
    overflow: hidden;
    transition: all .5s;
    font-size: 0.7rem;

    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;

    @include media-breakpoint-down(md) {
        justify-content: space-between;
        padding: 10px;

        @media screen and (max-width: 450px) {
            .give-me-some-space {
                min-height: 100px;
            }
        }

        @media screen and (orientation: landscape) {
            nav {
                margin-top: 2rem;
            }

            .contact {
                font-size: .5rem;
            }
        }
    }

    ul {
        display: flex;
        flex-direction: column;

        li {
            font-size: min(4vh, 2.5rem);
            transition: margin-left .2s;
            line-height: 1.2;

            .nav-link {
                transition: $transition-base;
                padding: min(0.5vh, 5px) 0.7rem;
            }

            &:hover {
                margin-left: 20px;
            }

            @include media-breakpoint-down(lg) {
                font-size: calc(1.5vh + 1.5vw + 3px);
            }
        }
    }
}

body.is-animating .menu-overlay {
    background: #eee;
    filter: saturate(0);
    cursor: wait;

    > * {
        pointer-events: none;
    }
}
