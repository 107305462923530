.input-group > label, .form-group > label {
  font-weight: bold;
}

.custom-checkbox, .custom-radio {
  input, label {
    cursor: pointer;
  }
}

.radio-group {
  margin: 2rem 0;
}

.custom-radio, .custom-checkbox {
  font-size: $font-size-base * 0.85;
}

.custom-radio + .custom-radio {
  margin-top: 0.5rem;
}

.radio-group .label {
  margin-bottom: 1rem;
  font-weight: $font-weight-bold;
}

[type="submit"] {
  margin-top: 2rem;
}

.form-control {
  border: none;
  border-bottom: 1px solid $light;
}

.input-group {
  display: flex;
  align-items: baseline;
  //border-bottom: 1px solid lighten($primary, 40);
  overflow: hidden;
  transition: $transition-base;
  line-height: 2;
  margin-bottom: 1rem;

  &:hover,
  &:active,
  &:focus {
    //border-bottom: 1px solid $primary;
  }

  label {
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: 1rem;
    min-width: 60px;

    @include media-breakpoint-down(md) {
      font-size: 1.1rem;
    }

    @include media-breakpoint-up(md) {
      min-width: 120px;
    }
  }

  .form-control {
    padding-left: 0;
    padding-right: 0;
  }
}

input[type="radio"],
input[type="checkbox"]{
  &:invalid, &.is-invalid {
    ~ .custom-control-label {
      color: inherit;

      &:before {
        border-color: $input-border-color;
      }
    }

    &:checked ~ .custom-control-label:before {
      border-color: $component-active-bg;
      background-color: $component-active-bg;
    }
  }
}

.sweetify {
  position: relative;
  height: 0;
  width: 0;
  overflow: hidden;

  * {
    position: absolute;
  }
}