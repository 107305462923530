footer {
  position: relative;
  z-index: 10;
  background-color: $dark;
  color: $white;
  padding: 3rem 1rem;

  nav {
    width: 100%;
    max-width: 1000px;

    ul {
      display: flex;
      justify-content: flex-start;

      &:before,
      &:after {
        content: '';
        display: block;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;

        li {
          align-self: center;
        }
      }
    }
  }

  a {
    color: $white;
    transition: $transition-base;
    text-transform: none;

    &.nav-link:after {
      content: '';
      display: block;
      width: 0%;
      height: 1px;
      transition: $transition-base;
      background-color: $white;
      margin-bottom: -1px;
    }
  }

  a:hover {
    color: $white;

    &.nav-link:after {
      width: 100%;
    }
  }

  .footer-bottom-line {
    display: flex;
    gap: 60px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 520px) {
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      .footer-bottom-nav {
        justify-content: center;
      }
    }

    .additional-logo {
      width: 100px;
    }
  }

  .footer-bottom-nav {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    gap: 4px;
  }
}