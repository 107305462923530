body[data-template="pages/teaser"] {

  .navbar-toggler-wrapper,
  .cart {
    color: $white;
  }

  .vue--burger-button.collapsed .bar {
    background-color: $white !important;
  }

  main>div:first-of-type {
    padding: 1rem 1rem 3rem 1rem;
    min-height: 520px;
    text-transform: lowercase;
    background-size: cover;
    background-position: center center;

    &.with-overlay {
      padding: 1rem 1rem 0 1rem;
    }

    h1 {
      color: $white;
    }

    .btn {
      background-color: #0000006e;

      &:hover {
        background-color: $white;
      }
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .background-logo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 270px;
    opacity: 0.9;
    z-index: -1;

    @media all and (max-width: 1140px) {
      width: 220px;
    }

    @media all and (max-height: 800px) {
      height: 180px;
      width: auto;
    }

    @media all and (max-height: 640px) and (max-width: 600px) {
      display: none;
    }
  }

  .text-overlay {
    @keyframes show-text-overlay {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes show-left-border {
      from {
        opacity: 0;
        transform: translate(-20px, -20px);
      }

      to {
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    @keyframes show-right-border {
      from {
        opacity: 0;
        transform: translate(20px, 20px);
      }

      to {
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    font-size: min(calc(2.1vw * 0.3 + 14px), 22px);
    background: #000;
    color: #fff;
    padding: 0 10vw 3rem 10vw;
    text-align: center;
    padding: max(60px, min(10vw, 100px)) max(40px, min(10vw, 70px));

    .text-container {
      max-width: 650px;
      margin: 0 auto;
      position: relative;
      opacity: 0;

      animation: show-text-overlay 0.4s ease 0.5s;
      animation-fill-mode: forwards;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(-1 * max(10px, min(5vw, 50px)));
        left: calc(-1 * max(10px, min(5vw, 50px)));
        width: 100px;
        height: 70px;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;

        opacity: 0;
        animation: show-left-border 0.4s ease 0.8s;
        animation-fill-mode: forwards;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(-1 * max(10px, min(5vw, 50px)));
        right: calc(-1 * max(10px, min(5vw, 50px)));
        width: 100px;
        height: 70px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;

        opacity: 0;
        animation: show-right-border 0.4s ease 0.8s;
        animation-fill-mode: forwards;
      }
    }
  }
}