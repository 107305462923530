// SPACING
$spacer: 1rem;

// GRID
$grid-breakpoints: (
        xs: 0,
        sm: 600px,
        md: 800px,
        lg: 1000px,
        xl: 1280px,
        hd: 1440px
);

$container-max-widths: (
        lg: 1000px,
        xl: 1220px,
        hd: 1440px
);

// COLORS
$white: #fff;

$primary: #464648;
$secondary: $white;
$headings-color: #050505;

$light: lighten($primary, 40);
$dark: #343434;
//
//$text-color: $primary;
//$text-color-inverse: $white;
$enable-responsive-font-sizes: true;

$link-hover-decoration: none;

//$body-bg: $white;
$body-color: $primary;
//
$border-radius: 0;
//$border-radius-lg: .3rem;
//$border-radius-sm: .2rem;
//

$input-color: $dark;
$input-border-radius: 0;
//$input-border-radius-lg: 0;
//$input-border-radius-sm: 0;
//$input-border-width: 0;
$input-focus-width: 0;
$input-focus-box-shadow: none;

$btn-border-radius: 0;

$font-family-base: "averta-regular", sans-serif;
$font-style: normal;

$font-size-base: 1rem; // 24px

$input-btn-font-size: $font-size-base;
$input-btn-font-size-sm: $font-size-base;
$input-btn-font-size-lg: $font-size-base;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$display1-size: 5.5rem;
$display2-size: 4.5rem;
$display3-size: 3.5rem;
$display4-size: 1.25rem;

$paragraph-margin-bottom: 1.3rem;
$headings-margin-bottom: 1.8rem;

$line-height-base: 1.55;
$headings-line-height: 1.3;
$btn-line-height: 2.25;

//$font-weight-base: $font-weight-normal;
//
//$input-btn-font-size: $font-size-base;
//
//$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.2rem;
$btn-font-weight: bold;
//
//$input-padding-y: $input-btn-padding-y;
//$input-padding-x: $input-btn-padding-x;

// NAVBAR

$navbar-padding-y: $spacer * 2;
$navbar-padding-x: $spacer * 4;

// TRANSITIONS
$cubic-bezier: cubic-bezier(0.645, 0.045, 0.355, 1);
$transition-base: all 0.25s $cubic-bezier;

// SPINNER
$spinner-width: 1.5rem;
$spinner-height: $spinner-width;
$spinner-border-width: 2px;
