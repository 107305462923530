.flex {
  display: flex;
  flex-direction: column;

  @include ie() {
    min-height: 0;

    > * {
      max-width: 100%;
    }
  }

  &.center.all {
    align-items: center;
    justify-content: center;
  }
}

.grow {
  flex: 1 1 auto;
}

.line-height-1-5 {
  line-height: 1.5;
}

.line-height-3 {
  line-height: 3;
}

.pointer {
  cursor: pointer;
}

.full-viewport-height {
  min-height: 100vh;
}

.img-cover {
  object-fit: cover;
}

.img-sticky {
  position: sticky;
  top: 0;

  &.vh-100 {
    @include media-breakpoint-down(md) {
      height: 70vh !important;
      object-position: center;
    }
  }
}

.p-content {
  padding: 7.5rem 5vw;

  @include media-breakpoint-up(xl) {
    padding: 7.5rem 5vw 5rem 8vw;
  }

  @include media-breakpoint-down(md) {
    padding: 5rem 10vw;
  }
}

@for $i from 1 through 5 {
  .m-children-#{$i} > * + * {
    margin-top: $spacer * $i;
  }
}
