body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

img {
  max-width: 100%;

  &.auto-height {
    height: auto;
  }

  &.auto-width {
    width: auto;
  }
}

[x-data] {
  opacity: 1;
  transition: $transition-base;
}

[x-cloak] {
  opacity: 0.5;
}

main {
  min-height: calc(100vh - 6rem);

  @include media-breakpoint-down(md) {
    min-height: max(650px,calc(100vh - 2rem));
  }
}